import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { StaffApi, CrewsApi } from "../../api";

import { TwoColumnDetails, Section } from "../../common/Details";
import { Spinner } from "../../common";

export const CrewDetails = () => {

  const location = useLocation();
  const { crewId } = useParams(0);

  const staffQuery = StaffApi.useStaffByCrew(crewId);
  const crewQuery = CrewsApi.useCrewsByID(crewId);

  const editPage = {
    pathname: `/crew/${crewId}/editCrew`,
    state: { background: location, name: "editCrew" },
  };

  if (crewQuery.isLoading || staffQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }
  if (!crewQuery.data) return null;

  return (
    <div className="w-full mx-auto mt-8 mb-28">
      <TwoColumnDetails isEditable heading="Crew Details" editBtn="Edit Crew" editLink={editPage}>
        <Section title="Crew Name" content={crewQuery?.data[0]?.crewName} />
        <Section title="Crew Leader" content={crewQuery?.data[0]?.crewName} />
        <Section title="Members" content={staffQuery?.data.map(e => e.staff_name).toString()} />
        <Section title="Status" content={crewQuery?.data[0]?.status} />
      </TwoColumnDetails>
    </div>
  );
};
