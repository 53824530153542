import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import clsx from 'clsx';
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { Button, Table, Spinner, Badge } from "../../common";

import { JobsApi, QuotesApi, CrewsApi, StaffApi, VisitsApi, CrewPayoutsApi } from "../../api";
import { numberFormat } from "../../utils";

export const CrewPayoutCalculator = ({ jobId }) => {

  // const quoteQuery = QuotesApi.useFetchQuote(quote_id);
  // const taskQuery = JobsApi.useTasks(jobId);
  const visitsQuery = VisitsApi.useGetVisitsByJob({ jobId });
  const queryClient = useQueryClient();
  const createCrewPayoutMutation = CrewPayoutsApi.useCreateCrewPayout();
  const updateVisitsMutation = VisitsApi.useUpdateVisit();
  // const crewsQuery = CrewsApi.useCrews();
  const staffQuery = StaffApi.useStaff();
  const crewPayoutQuery = CrewPayoutsApi.useCrewPayouts();
  
  if (visitsQuery.isLoading || staffQuery.isLoading || crewPayoutQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }



  // if (!crewsQuery.data || !staffQuery.data) return null;

  // const total = quoteQuery.data.erectDismantleTotal * (crewPercentage / 100);
  const otherRows = [];
  const rows = visitsQuery.data
  .filter(row => row.signed_off === true)
  .flatMap(row => {
    const taskRows = row.tasks?.map(task => {
      // Find the corresponding percent_completed value from task_completion_amounts
      const percentCompletedObj = row.task_completion_amounts.find(obj => obj.id === task.id);
      const percentCompleted = percentCompletedObj ? percentCompletedObj.percent_completed : 0;
      let paid_raw = 0;
      let paid = '';
      // let total_paid = 0;
      let task_value = 0;
      switch (row.type) {
        case 'Install':
          paid = `$${Number.parseFloat((task.crew_value * 0.65) * (percentCompleted/100)).toFixed(2)} (${percentCompleted}% Installed)`; // Calculate paid based on total_task_value and percent_completed
          paid_raw = Number.parseFloat((task.crew_value * 0.65) * (percentCompleted/100)).toFixed(2);
          task_value = `${(task.crew_value * 0.65)}`;
          break;
        case 'Dismantle':
          paid = `$${Number.parseFloat((task.crew_value * 0.35) * (percentCompleted/100)).toFixed(2)} (${percentCompleted}% Dismantled)`; // Calculate paid based on total_task_value and percent_completed
          paid_raw = Number.parseFloat((task.crew_value * 0.35) * (percentCompleted/100)).toFixed(2);
          task_value = ` ${(task.crew_value * 0.35)}`;
          break;
        case 'Variations':
          paid = `$${task.crew_value * (percentCompleted/100)} / ${task.crew_value} (${percentCompleted}%)`; // Calculate paid based on variationValue and percent_completed
          paid_raw = Number.parseFloat(task.crew_value * (percentCompleted/100)).toFixed(2);
          break;
        default:
          break;
      }

      let crewPayoutExists = false;
      // Find whole crew in crewPayoutsQuery:
      console.log(crewPayoutQuery.data);
      for (const crewPayout of crewPayoutQuery.data)
      {
        if(crewPayout.visit_id === row.id && crewPayout.task_id === task.id)
        {
          crewPayoutExists = true;
        }
      }

      // Loop through each crew
      // Find this crew from that list
      // Once crew is found we must loop through each crew member and add rows for their splits.
      // Once crew members are done we must add row for the team leaders split.

      // Teamleader
      if(!crewPayoutExists)
      {
        // Crewmembers
        let calculatedTaskValue = 0;
        for(const crewMember of row.crew.percentages)
        {
          const crewStaffMember = staffQuery.data?.find((staff) => staff.id === crewMember.id);
          const crewMemberPercentage = (crewMember.value / 100);
          const crewMemberPercentageRaw = crewMember.value;
          const paidAmountCents = (Number(paid_raw) * Number(crewMemberPercentage) * 100);
          const paidAmountCentsRounded = Math.floor(paidAmountCents);
          const paidAmount = paidAmountCentsRounded / 100;
          const paidAmountRounded = paidAmount.toFixed(2); // NOTE: This rounds down to ensure no
          calculatedTaskValue += Number(paidAmountRounded);
          otherRows.push({ ...{
            id: `${row.id}_${task.id}`,
            date: row.date,
            crew: row.crew.crewName,
            crew_id: row.crew?.id,
            staff_member: crewStaffMember.staff_name,
            staff_id: crewStaffMember.id,
            task: ' - ',
            type: row.type,
            paid: `$${paidAmountRounded} (${crewMemberPercentageRaw}% of $${paid_raw})`,
            paid_raw: paidAmountRounded,
            percent_split: crewMemberPercentageRaw,
            task_value: null,
            is_whole_crew:false,
            job_id: jobId,
          } });
        }

        const newRow = {
          id: `${row.id}_${task.id}`,
          date: row.date,
          crew: row.crew?.crewName,
          crew_id: row.crew?.id,
          staff_member: "Whole Crew",
          staff_id: null,
          task: task.description,
          type: row.type,
          paid,
          paid_raw: Number.parseFloat((task.crew_value * 0.35) * (percentCompleted/100)).toFixed(2),
          percent_split: percentCompleted,
          task_value: calculatedTaskValue,
          is_whole_crew:true,
          job_id: jobId,
        };

        otherRows.push({ ...newRow });
        return newRow;
     }
      return null;
     
      
    });
    
    
    return rows;
  });

  const sendCrewPayout = async (id, rows) => {
    console.log("Sending payout to payouts table for id", id);
    console.log(rows);
    const ids = id.split('_');
    const visitId = ids[0];
    const taskId = ids[1];
    for(const row of rows)
    {
      console.log(row);
      const crewPayoutPayload = {
        visit_id: visitId,
        task_id: taskId,
        staff_id: row.staff_id,
        crew_id: row.crew_id,
        job_id: row.job_id,
        visit_pay: row.paid_raw,
        pay_percent: row.percent_split,
        task_total_available: row.task_value,
        staff_name: row.staff_member,
        is_whole_crew: row.is_whole_crew
      };
      try{
      createCrewPayoutMutation.mutateAsync(crewPayoutPayload);
      }
      catch(error){
        console.log("Error Creating Crew Payout");
        console.log(error);
      }
    }
    
    

    queryClient.refetchQueries("visits");
    // Post the crew payout to the crew payouts table and then refetch query for visits
  }
  

  return (
    <div className="w-full mx-auto mt-8">
      <div>
        <h2 className="text-lg px-8 mb-2 leading-6 font-large text-gray-900 mt-6">Crew Payout By Task</h2>
        <Table
          cols={[
            {
              Header: "Date",
              accessor: "date",
            },
            {
              Header: "Crew Name",
              accessor: "crew",
            },
            {
              Header: "Staff Name",
              accessor: "staff_member",
            },
            {
              Header: "Task",
              accessor: "task",
            },
            {
              Header: "Type",
              accessor: "type",
            },
            {
              Header: "Potential Visit Pay",
              accessor: "paid",
            },
            {
              Header: "Total Actual Payout",
              accessor: "task_value",
              Cell: ({ row }) => {
                const value = row?.original?.task_value
                  ? numberFormat.format(row?.original?.task_value)
                  : ' - ';
                  return value;
              }
            },
            {
              Header: "Total Paid",
              accessor: "total_paid",
              Cell: ({ row }) => {
                const value = row?.original?.total_paid
                  ? numberFormat.format(row?.original?.total_paid)
                  : ' - ';
                  return value;
              }
            },
            {
              Header: "Send to Crew Payouts",
              accessor: "crewpayoutsbutton",
              Cell: ({ row }) => (
                row?.original?.is_whole_crew ? 
                <button
                  type="button"
                  onClick={() => {
                    // setTaskId(row.original.id);
                    // setOpenTaskEdit(true);
                    sendCrewPayout(row.original.id, otherRows.filter((record) => record.id === row.original.id));
                  }}
                >
                  <ArrowUpTrayIcon className="text-green-500 h-4 w-4" />
                </button> : ""
              ),
            },
          ]}
          tableData={otherRows}
          // searchPlaceholder="Search Scaffold Tags"
          displaySearch={false}
          isBasic
          displayPagination={false}
          pageSize={1000}
        />
      </div>
    </div>
  );
};
