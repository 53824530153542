/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import moment from "moment";
import { SideModal, Input, TextArea, Dropdown, DateSelect } from "../../../common";
import { JobsApi, VisitsApi } from "../../../api";



const typeOptions = [
  { value: "Scaffolding", label: "Scaffolding" },
  { value: "Stairs", label: "Stairs" },
  { value: "Roof", label: "Roof" },
  { value: "Propping", label: "Propping" },
  { value: "Edge Protection", label: "Edge Protection" },
  { value: "Shrinkwrap", label: "Shrinkwrap" },
  { value: "Geda 1200", label: "Geda 1200" },
];


export function SignoffVisit({ visitId, jobId, open, setOpen }) {
  const updateTaskMutation = JobsApi.useUpdateTask();
  const updateVisitMutation = VisitsApi.useUpdateVisit();
  const visitQuery = VisitsApi.useFetchVisit(visitId);

  if (visitQuery.isLoading) {
    return (
      <div />
    );
  }

  if (!visitQuery.data) return null;
  const visit = visitQuery.data;

  const initialData = visit.tasks.reduce((acc, task) => ({
    ...acc,
    [task.id]: {
      id: task.id,
      is_install_visit: (visit.type === "Install") ? 1 : 0,
      old_percent: ((visit.type === "Install") ? task.percentage_erect : task.percentage_dismantle),
      new_percent: ((visit.type === "Install") ? task.percentage_erect : task.percentage_dismantle),
      percentage_erect: task.percentage_erect,
      percentage_dismantle: task.percentage_dismantle,
      PO_Number: task.PO_Number || "",
      zone: task.zone || "",
      zone_label: task.zone_label || "",
      type: task.type || "",
      description: task.description || "",
      percentage_complete: task.percentage_complete || 0,
      total_hours: task.total_hours || "",
      Requester: task.Requester || "",
      LastEditDate: task.LastEditDate || moment().format("DD/MM/YYYY"),
      variationValue: task.variationValue || 0,
      hireRate: task.hireRate || 0,
    },
  }), {});

const taskIds = visit.tasks.map(task => task.id);
initialData.taskIds = taskIds;
console.log(JSON.stringify(initialData));

  // const crewValue = tasksQuery.data.total_task_value;
  // console.log(tasksQuery.data);
  return (
    <div>
      <Formik
        initialValues={initialData}
        onSubmit={async (values, { resetForm }) => {
          const visitPayload = {
            task_completion_amounts: [],
            signed_off: true
          };
          
          values.taskIds.forEach(async taskId => {
            const task = values[taskId];
            const taskPayload = {
              PO_Number: task.PO_Number || "",
              zone: task.zone || "",
              zone_label: task.zone_label || "",
              type: task.type || "",
              description: task.description || "",
              percentage_complete: task.percentage_complete || 0,
              total_hours: task.total_hours || "",
              Requester: task.Requester || "",
              LastEditDate: task.LastEditDate || moment().format("DD/MM/YYYY"),
              variationValue: task.variationValue || 0,
              hireRate: task.hireRate || 0,
            };
            
            if(task.is_install_visit)
            {
              taskPayload.percentage_erect = task.new_percent;
              taskPayload.percentage_complete = (task.percentage_dismantle * 0.35) + (Number(task.new_percent) * 0.65)
            }
            else
            {
              taskPayload.percentage_dismantle = task.new_percent;
              taskPayload.percentage_complete = (Number(task.new_percent) * 0.35) + (task.percentage_erect * 0.65)
            }
            
            visitPayload.task_completion_amounts.push({
              id: taskId,
              percent_completed: task.new_percent - task.old_percent
            });

            console.log("Updating Visit tasks");
            try {
              await updateTaskMutation.mutateAsync({
                payload: taskPayload,
                taskId: task.id,
                type: "task",
              });
              resetForm();
              setOpen(false);
            } catch (err) {
              console.log("ERROR UPDATING A TASK", err);
            }
          });
          console.log("Updating Visit");
            try {
              await updateVisitMutation.mutateAsync({
                visitId: visit.id,
                visit: visitPayload,
              });
              resetForm();
              setOpen(false);
            } catch (err) {
              console.log("ERROR UPDATING A TASK", err);
            }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading="Signoff Visit"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType="submit"
          >
            <div className="flex">
            <h3 className="px-4 pt-4 text-lg font-semibold leading-5">Visit: {visit.date}</h3>
            <h3 className="px-4 pt-4 text-lg font-semibold leading-5">Type: {visit.type}</h3>
            <br />
            <br />
            <br />
            </div>
            {visit.tasks?.map(task => (
              <div>
                <h2 className="px-4 pt-4 text-base font-semibold leading-5">Task: {task.description}</h2>
                <h1 className="px-4 pt-4 text-base font-semibold leading-5">Type: {task.type}</h1>
                <h1 className="px-4 pt-4 text-base leading-5">Percent changed this visit: {(values[task.id]?.new_percent - values[task.id]?.old_percent)}%</h1>
                <br />
                <div className="flex">
                <Input
                title="Current Percent"
                id={`${task.id}.old_percent`}
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values[task.id].old_percent}
                isDisabled
                />
                <Input
                title="New Percent"
                id={`${task.id}.new_percent`}
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values[task.id].new_percent}
                />
                </div>

              </div>
            ))}
            {/* <div className="">
              <TextArea
                title="Description"
                id="description"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={JSON.stringify(values)}
              />
            </div> */}
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
