import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { PencilAltIcon } from "@heroicons/react/outline";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import moment from "moment";
import supabase from "../../api/supabase";
import { TwoColumnDetails, Section } from "../../common/Details";
import { EditTimesheetDetails } from "../../components/Timesheet";
import { Button, Spinner, Table, Tabs } from "../../common";
import { CrewPayouts } from "../../components/Jobs";
import { JobsApi, TimesheetsApi } from "../../api";
import { useFetchVisitTimesheets, useVisitTimesheet } from "../../api/Jobs";

export const TimesheetDetails = () => {
  const user = supabase.auth.user();
  const [editFormDetail, setEditFormDetail] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const { timesheetId } = useParams(0);

  const { data, isLoading } = TimesheetsApi.useFetchTimesheet(timesheetId);
  const { data: JobsData, isLoading: jobsLoading } = JobsApi.useJobs();
  const items = [
    { label: "Payouts", id: 0 },
  ];

  const {data: visitTimesheetData, isLoading: visitTimesheetLoading} = useVisitTimesheet(data?.visitTimeSheetId);
  console.log("vtd: ", visitTimesheetData);

  if (isLoading || jobsLoading || visitTimesheetLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }




  // Find visitTimesheets where 


  function calcHours() {
    const start = moment(data.time_on, "HH:mm");
    const finish = moment(data.time_off, "HH:mm");

    const duration = moment.duration(finish.diff(start));
    const hours = duration.asHours();
    return hours.toFixed(2);
  }
  console.log(data);
  if (!data) return null;

  return (
    <div className="w-full mx-auto mt-8 mb-28">
      <TwoColumnDetails heading="Timesheet Details" isEditable={false}>
        <Section title="Created On" content={data.date} />
        <Section title="Staff member" content={data.staff?.staff_name} />
        {/* <Section title="Total hours" content={data?.hours} />
        <Section title="Break Time: Yes/No" content={data.breakTaken} /> */}
        <Section title="Status" content={data.status} />

        {/* <Section title={data.travelTime ? "Travel Time" : null} content={data.travelTime} /> */}
        {/* <Section
          title={data.meal === "Yes" ? "Meal" : null}
          content={data.meal === "Yes" && data.meal}
        />
        <Section title={null} content={null} /> */}
        {/* <Section
          title={data.laha === "Yes" ? "Laha" : null}
          content={data.laha === "Yes" && data.laha}
        />
        <Section
          title={data.accom === "Yes" ? "Accom" : null}
          content={data.accom === "Yes" && data.accom}
        /> */}
        <Section />
        <Section
          title="Signed In Time"
          content={data.time_on}
        />
        <Section
          title="Signed out Time"
          content={data.time_off}
        />

        {/* <Section
          title="Sign In Location"
          content={MapContainerTimesheet(data.LongitudeLogIn, data.LatitudeLogIn)}
        />
        <Section
          title="Sign Out Location"
          content={MapContainerTimesheet(data.LongitudeLogOut, data.LatitudeLogOut)}
        /> */}
      </TwoColumnDetails>
      <br />
      <div className="px-8">
        <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={items} />
      </div>

      {tabIndex === 0 && <CrewPayouts staffId={data.staff_id} jobId={visitTimesheetData[0].job_id} />}
      {open && (
        <EditTimesheetDetails
          open={open}
          setOpen={setOpen}
          formType="edit"
          data={editFormDetail}
          setEditFormDetail={setEditFormDetail}
        />
      )}
    </div>
  );
};

const MapContainerTimesheet = (Longitude, Latitude) => {
  const vehicleIconMap = new Icon({
    iconUrl: "/marker.svg",
    iconSize: [40, 40],
  });

  return (
    <MapContainer
      style={{ height: "45vh", width: "100%", zIndex: "0" }}
      center={[Latitude || 0, Longitude || 0]}
      zoom="16"
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker icon={vehicleIconMap} position={[Latitude || 0, Longitude || 0]}>
        <Popup>Vehicle</Popup>
      </Marker>
    </MapContainer>
  );
};
