import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

async function updateCrew({ crewPayload, id }) {
  const { data, error } = await supabase.from("crews").update(crewPayload).match({ id });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useUpdateCrew() {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((crewData) => updateCrew(crewData), {
    onSuccess: () => {
      queryClient.refetchQueries("crews");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully Updating Crew.`,
      });
    },
    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed Updating Crew Leader with crew",
        content: err?.message,
      });
    },
    mutationFn: updateCrew,
  });
}
