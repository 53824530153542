import { useQuery } from "react-query";
import supabase from "../supabase";

export async function getCrews() {
  const { data, error } = await supabase
    .from("crews")
    .select("*, staff:crewLead(staff_name)")
    .order("id", { ascending: false });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useCrews() {
  return useQuery("crews", () => getCrews());
}
