import { XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Dropdown } from "../../../common/Dropdown";

import { useRatesStore } from "../Rates/store";
import { numberFormat } from "../../../utils";
import { ConfirmationDialog } from "../../../common/Confirmation/Confirmation";
import { Button } from "../../../common";

export function Line({
  zoneOptions,
  zoneLabels,
  quoteLine,
  handleRemove,
  index,
  handleProductLineChange,
  handleDimensionsLineChange,
  handleEDChangeLineChange,
  rates,
  estimatedWay
}) {
  const typeOptions = rates.filter(e => e.type === "General" || e.type === "Both").map(e => ({ "value": e.service, "label": e.service }));
  const [label, setLabel] = useState("");
  const [zone, setZone] = useState(!quoteLine?.zone);
  const [serviceType, setServiceType] = useState(null);
  // const [hireFee, setHireFee] = useState(null);

  const { quantity, dismantle, percentageWeeklyHireFee, hireFee } = quoteLine;
  useEffect(() => {
    setServiceType(quoteLine?.type);
  }, [quoteLine]);

  useEffect(() => {
    const rateData = rates.find((rate) => rate.service === serviceType);
    handleDimensionsLineChange(
      index,
      quantity,
      rateData,
      hireFee
    );
  }, [quantity,hireFee]);

  useEffect(() => {
    const findLabel = zoneLabels.find((el) => Number(el.id) === Number(quoteLine.zone));

    setLabel(findLabel?.label);

    handleProductLineChange(index, "zoneLabel", findLabel?.label);
  }, [quoteLine.zone]);

  return (
    <tr key={index}>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-20">
        <Dropdown
          hasLabel={false}
          options={zoneOptions}
          id={`zone${index}`}
          value={quoteLine.zone}
          onChange={(e) => {
            setZone(false);
            handleProductLineChange(index, "zone", e);
          }}
          repeatingForm
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-40">
        <input
          id={`zoneLabel${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
          value={label}
          name="zoneLabel"
          disabled
          onChange={(e) => handleProductLineChange(index, "zoneLabel", e.target.value)}
        />
      </td>
      <td className="h-6 px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-52">

        <Dropdown
          hasLabel={false}
          options={typeOptions}
          id={`type${index}`}
          value={quoteLine.type}
          disabled={zone}
          onChange={(e) => {
            setServiceType(e);
            handleProductLineChange(index, "type", e);
          }}
          repeatingForm
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
        <input
          id={`description${index}`}
          type="text"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.description}
          onChange={(e) => handleProductLineChange(index, "description", e.target.value)}
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
        <input
          id={`quantity${index}`}
          type="number"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.quantity}
          // disabled={!serviceType || estimatedWay !== "Hours"}
          onChange={(e) => handleProductLineChange(index, "quantity", e.target.value)}
        />
      </td>
      {estimatedWay !== 'Meters' && <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
        <input
          id={`totalDays${index}`}
          type="number"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.totalDays}
          disabled
          onChange={(e) => null}
        />
      </td>}

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`dismantle${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.dismantle))}
          disabled
        />
      </td>


{/*       <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`percentageWeeklyHireFee${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
          value={Number(quoteLine.percentageWeeklyHireFee)}
          onChange={(e) => handleProductLineChange(index, "percentageWeeklyHireFee", e.target.value)}
        />
      </td> */}
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`hireFee${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
          value={(Number(quoteLine.hireFee))}
          onChange={(e) => handleProductLineChange(index, "hireFee", Number(e.target.value))}
          /* disabled={Number(quoteLine.percentageWeeklyHireFee) !== 0} */
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`total${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.total))}
          onChange={(e) => handleProductLineChange(index, "total", e.target.value)}
          disabled
        />
      </td>
      <td className="px-2 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
        <ConfirmationDialog
          icon="danger"
          title="Delete Line Item"
          body="Are you sure you want to delete this item? This action is unrecoverable!"
          triggerButton={
            <button type="button">
              <XMarkIcon className="flex-shrink-0 h-4 w-4 text-red-500" aria-hidden="true" />
            </button>
          }
          confirmButton={
            <Button
              variant="danger"
              className="bg-red-600 text-white"
              onClick={async (e) => {
                e.preventDefault();
                handleRemove(index, quoteLine.id);
              }}
            >
              Delete Line
            </Button>
          }
        />
      </td>
    </tr>
  );
}
