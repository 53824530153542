import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { CrewForm } from "../../components/crews";

export const EditCrew = (props) => {
  const [open, setOpen] = useState(true);
  const { staffId } = useParams();

  return (
    <>
      <CrewForm open={open} setOpen={setOpen} formType="edit" />
    </>
  )
};
