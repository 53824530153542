import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

async function createCrewPayout(crew_payout) {
  const { data, error } = await supabase.from("crew_payouts").insert(crew_payout);

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useCreateCrewPayout() {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((crew_payout) => createCrewPayout(crew_payout), {
    onSuccess: () => {
      queryClient.refetchQueries("crew_payouts");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully created Crew Payout.`,
      });
    },
    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed to reate Crew Payout",
        content: err?.message,
      });
    },
    mutationFn: createCrewPayout,
  });
}
