import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Formik } from "formik";
import Select from "react-select";
import { SideModal, Input, Dropdown, Spinner } from "../../common";
import { useUpdateStaff } from "../../api/Staff";
import { CrewsApi, StaffApi } from "../../api";

export function CrewForm({ heading, open, setOpen, formType = "create" }) {
  const { crewId } = useParams();
  const [crew, setCrew] = useState([]);
  const createCrewMutation = CrewsApi.useCreateCrew();
  const updateCrewMutation = CrewsApi.useUpdateCrew();
  const updateStaffMutation = useUpdateStaff();

  const crewExisting = CrewsApi.useCrews();

  const staff = StaffApi.useStaff();
  const history = useHistory();

  useEffect(() => {
    console.log(membersOptions, "membersOptions");
  }, [membersOptions]);
  useEffect(() => {
    let isCurrent = true;

    if (!open && crewId) {
      history.goBack();
    }
    if (crewId) {
      CrewsApi.getCrewsByID(crewId).then((data) => {
        if (isCurrent) setCrew(data);
      });
    }
    return () => {
      isCurrent = false;
    };
  }, [crewId, open]);
  if (formType === "edit" && (staff.isLoading || !crew?.length || crewExisting.isLoading))
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );

  const crewLeadOptions = staff?.data
    ?.filter((e) => e.type === "Crew Leader")
    ?.map((e) => {
      const op = { value: e.id, label: e.staff_name };
      return op;
    });
  console.log(staff.data, "staff.data");

  const membersOptions = staff?.data
    ?.filter((e) => e.crew === null /* && e.type !== "Crew Leader" */)
    ?.map((e) => {
      const op = { value: e.id, label: e.staff_name };
      return op;
    });
  const membersPrevius = staff?.data
    ?.filter((e) => (Number(e.crew) === Number(crewId)))
    ?.map((e) => {
      const op = { value: e.id, label: e.staff_name };
      return op;
    });
  const crewStatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  return (
    <div>
      <Formik
        initialValues={{
          crewName: crew[0]?.crewName || "",
          crewLeader: crew[0]?.crewLead || "",
          members: membersPrevius || [],
          percentages: crew[0]?.percentages || [],
          totalPercentage:
            crew[0]?.percentages?.reduce(
              (previousValue, currentValue) => previousValue + currentValue.value,
              0,
            ) || 0,
          status: crew[0]?.status || "Active",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.crewName) errors.crewName = "Crew Name Is Required.";
          if (!values.crewLeader) errors.crewLeader = "Crew Leader Is Required.";
          if (values.members.length === 0) errors.members = "Staff Is Required.";
          if (values.percentages.find((row) => row.value === 0) !== undefined)
            errors.percentages = "Percentage can't be 0.";
          if (values.totalPercentage !== 100)
            errors.totalPercentage = "Total Percentage is not 100%.";
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          const crewPayload = {
            crewName: values.crewName,
            crewLead: values.crewLeader,
            percentages: values.percentages,
            status: values.status,
          };

          const staffVals = values.members.map((item) => Number(item.value));

          let newcrewId = null;

          try {
            let resultUser;
            if (formType === "edit") {
              resultUser = await updateCrewMutation.mutateAsync({ crewPayload, id: crewId });
            } else {
              resultUser = await createCrewMutation.mutateAsync(crewPayload);
              newcrewId = resultUser[0].id;
            }
            // staffVals
            for (const member of membersPrevius) {
              // eslint-disable-next-line no-await-in-loop
              await updateStaffMutation.mutateAsync({
                staff: { crew: null },
                staffId: Number(member.value),
              });
            }

            for (const member of staffVals) {
              updateStaffMutation.mutateAsync({
                staff: { crew: crewId || newcrewId },
                staffId: Number(member),
              });
            }

            setOpen(false);
            resetForm();
          } catch (err) {
            console.log("Error with process", err);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading={heading}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType={formType}
          >
            <div className="flex items-center">
              <Input
                title="Crew Name"
                id="crewName"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.crewName}
                error={errors.crewName}
              />
              <Dropdown
                label="Crew Leader"
                id="crewLeader"
                options={crewLeadOptions}
                value={values.crewLeader}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.crewLeader}
              />
            </div>
            <div className="w-full">
              <Dropdown
                label="Status"
                id="status"
                options={crewStatusOptions}
                value={values.status}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.status}
              />
            </div>
            <div className="p-6 px-4 py-4">
              <label
                id="staff"
                htmlFor="staff"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Staff Members
              </label>
              <Select
                // defaultValue={[colourOptions[2], colourOptions[3]]}
                isMulti
                name="members"
                id="members"
                options={membersOptions}
                onChange={async (value) => {
                  setFieldValue("members", value);
                  let list = value.map((e) => {
                    const newData = {
                      id: e.value,
                      value: values.percentages.find((f) => f.id === e.value)?.value || 0,
                    };
                    return newData;
                  });
                  console.log(list, "list");
                  if (values.crewLeader) {
                    list = [
                      ...list,
                      {
                        id: values.crewLeader,
                        value:
                          values.percentages.find((f) => f.id === values.crewLeader)?.value || 0,
                      },
                    ];
                  }

                  setFieldValue("percentages", list);
                }}
                className="w-full basic-multi-select"
                classNamePrefix="select"
                value={values.members}
                error={errors.members}
              />
              {errors.members && (
                <div className="">
                  <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                  Staff Is Required
                </div>
              )}
            </div>

            {values.crewLeader && (
              <Input
                title={`% For ${crewLeadOptions.find((e) => e.value === values.crewLeader)?.label}`}
                id={`memberper-${values.crewLeader}`}
                type="number"
                value={values.percentages.find((e) => e.id === values.crewLeader)?.value || 0}
                handleChange={(event) => {
                  const newpercentages = values.percentages.map((e) => {
                    if (e.id === values.crewLeader) {
                      const newRow = {
                        id: values.crewLeader,
                        value: Number(event.target.value),
                      };
                      return newRow;
                    }
                    return e;
                  });
                  const totalPercentage = newpercentages.reduce(
                    (previousValue, currentValue) => previousValue + currentValue.value,
                    0,
                  );
                  setFieldValue("percentages", newpercentages);
                  setFieldValue("totalPercentage", totalPercentage);
                }}
                handleBlur={handleBlur}
              />
            )}

            <div className="">
              {values.members.map((row) => {
                const info = row;
                return (
                  <>
                    <Input
                      title={`% For ${row?.label}`}
                      id={`memberper-${row.value}`}
                      type="number"
                      value={values.percentages.find((e) => e.id === row.value)?.value || 0}
                      handleChange={(event) => {
                        const newpercentages = values.percentages.map((e) => {
                          if (e.id === row.value) {
                            const newRow = {
                              id: row.value,
                              value: Number(event.target.value),
                            };
                            return newRow;
                          }
                          return e;
                        });
                        const totalPercentage = newpercentages.reduce(
                          (previousValue, currentValue) => previousValue + currentValue.value,
                          0,
                        );
                        setFieldValue("percentages", newpercentages);
                        setFieldValue("totalPercentage", totalPercentage);
                      }}
                      handleBlur={handleBlur}
                    />
                  </>
                );
              })}
              {errors.percentages && (
                <div className="">
                  <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                  {errors.percentages}
                </div>
              )}
              {errors.totalPercentage && (
                <div className="">
                  <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                  {errors.totalPercentage}
                </div>
              )}
            </div>
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
