import { useQuery } from "react-query";
import supabase from "../supabase";

export async function getCrewsByID(status) {
  const { data, error } = await supabase
    .from("crews")
    .select("*, staff:crewLead(id,staff_name)")
    .match({ status });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useCrewsByStatus(status) {
  return useQuery("crewses", () => getCrewsByID(status));
}