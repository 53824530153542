import React from "react";
import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingBottom: 40,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  terms: {
    display: 'flex',
    marginTop: '20px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionTerms: {
    width: '48%',
    display: 'flex',
  },
  termsWrapper: {
    display: 'flex',
    marginTop: '3px',
    flexDirection: 'row',
  },
  indexWrapper: {
    width: '24px',
  },
  textWrapper: {
    width: '98%',
  },
  index: {
    fontFamily: "Open Sans",
    fontWeight: "normal",
    lineHeight: '1.2',
    fontSize: 7,
  },
  indexBold: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    lineHeight: '1.2',
    fontSize: 7,
  },
  indexRed: {
    fontFamily: "Open Sans",
    fontWeight: "normal",
    lineHeight: '1.2',
    color: '#ff1a1a',
    fontSize: 7,
  },
  titleTerms: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    marginLeft: '-3px',
    lineHeight: '1.5',
    fontSize: 7,
  },
  titleTermsRed: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    marginLeft: '-3px',
    lineHeight: '1.5',
    color: '#ff1a1a',
    fontSize: 7,
  },
  textTerms: {
    fontFamily: "Open Sans",
    textAlign: 'justify',
    fontWeight: "normal",
    lineHeight: '1.2',
    fontSize: 7,
  },
  textTermsRed: {
    fontFamily: "Open Sans",
    textAlign: 'justify',
    fontWeight: "normal",
    lineHeight: '1.2',
    color: '#ff1a1a',
    fontSize: 7,
  },
  listWrapper: {
    display: 'flex',
    marginTop: '2px',
    flexDirection: 'row',
    width: '94%',
  },
  subListWrapper: {
    marginLeft: '15px',
  },
  indexList: {
    fontFamily: "Open Sans",
    fontWeight: "normal",
    marginRight: '10px',
    lineHeight: '1.2',
    fontSize: 7,
  },
  textList: {
    fontFamily: "Open Sans",
    textAlign: 'justify',
    fontWeight: "normal",
    lineHeight: '1.2',
    fontSize: 7,
  },
  textListRed: {
    fontFamily: "Open Sans",
    textAlign: 'justify',
    fontWeight: "normal",
    lineHeight: '1.2',
    color: '#ff1a1a',
    fontSize: 7,
  },
  listRed: {
    color: '#ff1a1a',
  }
});

export const AdditionalTerms = () => (
  <>
    <Page size="A4" style={styles.page}>
      <View style={styles.terms}>
        <View style={styles.sectionTerms}>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What is the purpose of this agreement?
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>1.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                This agreement sets out the terms that apply to the relationship between you (and “your”) and Advance Scaffold
                (Auckland) <Text style={styles.titleTerms}>Limited</Text> trading
                as <Text style={styles.titleTerms}>Access Scaffolding</Text> and <Text style={styles.titleTerms}>Advance Scaffold</Text> (“we”, “us” and “our”).
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What information about you can we collect?
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>2.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                You agree to provide us with and allow us to use information necessary to give effect to this agreement and provide you with the goods and services (“your information”). We will not hold your information longer than required for the purposes of its collection.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>2.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Unless your consent is withdrawn in writing, you agree to us disclosing any of your information:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>to give effect to or enforce our obligations under this agreement or additional agreement;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>when authorised by you or required by law;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>to assess your credit worthiness; and</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>to assess your credit worthiness; and</Text></Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>2.3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                We will not use your information unless we have reasonably ensured it is accurate, complete, relevant and not misleading. If we give your information to another entity we will do everything reasonably within our power to prevent unauthorised use or disclosure of your informatio
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>2.4</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                You may access any of your information and ask us to correct any mistakes in it.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What are our goods and services?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>3.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Goods” and “services” means and includes without limitation all goods, inventory and products such as scaffolding, planks, boards and materials (whether separate, attached to something or performed work on) and services such as delivery, erection, dismantling, distribution and consultation and out of pocket expenses provided to you by us and identified:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>in any account, agreement, application, order form, supply request, packing slip, payment claim, wholesale list, email, quote, invoice, statement or other document issued by either party, all of which are deemed to be incorporated into and form part of this agreement; or</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>as ours by marking or a manner of storage enabling them to be identified as ours.</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>4</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What is the price?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>4.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                The price is the cost of the goods and services as agreed between you and us from time to time excluding GST and out of pocket expenses such as freight. If no price is stated, the price will be the cost that we provide the goods and services at the time of your order. The price is subject to change at any time.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>5</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What happens when we give you a quote?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>5.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                If we give you a quote for goods and services:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>it will be valid for thirty (30) days from the date of issue, unless withdrawn or stated otherwise;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>it will be exclusive of GST, unless stated otherwise;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>it will be subject to site inspection and availability of the goods;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>you are solely responsible for the accuracy of information on which the quotation is based;</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>the cost of labour detailed in the quote is based on the following, unless stated otherwise: </Text></Text>
                </View>
              </View>

              <View style={styles.termsWrapper}>
                <View style={styles.subListWrapper}>
                  <View style={styles.listWrapper}>
                    <View style={styles.indexList}><Text style={styles.list}>o</Text></View>
                    <View style={styles.textListWrapper}>
                      <Text style={styles.textList}>ground is level and free of obstructions;</Text>
                    </View>
                  </View>
                  <View style={styles.listWrapper}>
                    <View style={styles.indexList}><Text style={styles.list}>o</Text></View>
                    <View style={styles.textListWrapper}>
                      <Text style={styles.textList}>erection and dismantling occurring between 7:30am-4:00pm on weekdays excluding holidays. Work outside these hours incurs additional cost per man per hour; and</Text>
                    </View>
                  </View>
                  <View style={styles.listWrapper}>
                    <View style={styles.indexList}><Text style={styles.list}>o</Text></View>
                    <View style={styles.textListWrapper}>
                      <Text style={styles.textList}>using our labour force or subcontractors without allowance for site agreements or special conditions that may apply on site.</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>you will be responsible for increased costs or receive the benefit of decreased costs resulting from any subsequent changes to the quote due to any inadequate or inaccurate information and request or requirement for additional goods and services or alterations;</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>gantry, fans, screening, toe boards, encapsulation materials and fittings will require variation, unless stated otherwise; and</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>we have the right to alter the quote because of circumstances beyond our control or clerical or computer error.</Text></Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>6</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                When and how do you pay us?
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexRed}>6.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTermsRed}>
                You agree to pay us in full:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.listRed}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textListRed}>unless stated otherwise in writing, on the due date as determined below:</Text></Text>
                </View>
              </View>

              <View style={styles.termsWrapper}>
                <View style={styles.subListWrapper}>
                  <View style={styles.listWrapper}>
                    <View style={styles.indexList}><Text style={styles.listRed}>o</Text></View>
                    <View style={styles.textListWrapper}>
                      <Text style={styles.textListRed}>for goods used on commercial premises, on or before the 20th day of the month following the date of our invoice; or</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.sectionTerms}>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexRed}> </Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTermsRed}> </Text>
              <View style={styles.termsWrapper}>
                <View style={styles.subListWrapper}>
                  <View style={styles.listWrapper}>
                    <View style={styles.indexList}><Text style={styles.listRed}>o</Text></View>
                    <View style={styles.textListWrapper}>
                      <Text style={styles.textListRed}>for goods used on residential premises, on or before the 7th day following the date of our invoice, or on the day any scaffold supplied is erected as per our quotation conditions for residential properties</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.listRed}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textListRed}>interest on any amount you owe after the due date at a rate of 2.5% per month or part month.</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.listRed}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textListRed}>any expenses, including debt collection, legal, reinstatement/reconditioning/replacement costs that we incur as a result of enforcing any of our rights contained in this agreement.</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.listRed}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textListRed}>without set-off, deduction or counterclaim; and</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.listRed}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textListRed}>a deposit when required.</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.listRed}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textListRed}>Failure to comply with these specific terms may result in us not returning to site until payment is received in full for all invoices in full.</Text></Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>6.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                We may require a credit card retention for goods and services, the value of which you agree will be deducted from your credit card. All payments by credit card will incur an extra charge of 2.5% of the value of the invoice.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>6.3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                You agree to us allocating or reallocating any payment received from you towards any invoice.  If no allocation is made then it is deemed to be in such a way that preserves the maximum value of our purchase money security interest in the goods.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>6.4</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                We may require progress payments and invoice by payment claims under the Construction Contracts Act 2002.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>7</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What warranties apply?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>7.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Manufacturers’ warranties and guarantees apply.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>7.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                If you are in trade and/or are a business, you agree that you and we contract out of the Fair Trading Act 1986 and Consumer Guarantees Act 1993 to the extent permissible by law.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>7.3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                We are not liable for delay or failure to perform our obligations if the cause is beyond our control.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>7.4</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Subject to 7.2 and 7.3, if we are deemed liable to you for any loss or damage of any kind, arising from the provision of goods and services to you, including consequential loss, whether suffered or incurred by you or another person or entity and whether in contract tort or otherwise, then you agree that our total liability is limited to the value of the goods and services provided to you.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>8</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What extra terms apply when you hire from us?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>8.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                If you hire any goods from us you agree:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>to check the goods on delivery or pickup and sign the Hire Agreement, signature of which confirms good condition of the equipment;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>not to deal with the goods in any way that is detrimental to us and maintain goods in the condition to which they were provided, subject to fair wear and tear. Reconditioning/renewal/replacement costs may apply;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>not to sublease, remove, transfer, alter or modify the goods without our prior written consent;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>that if you are erecting the goods you will follow our instructions;</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>you will be liable for any damage to or loss of the goods until their delivery is receipted by us and will pay the cost of repairing or replacing the goods;</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>you will be solely responsible for insurance against loss or damage unless agreed in writing that we will provide such insurance for 4% of the total price which will be added to the invoice;</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>that we hold a security interest over any goods that you hire for a term greater than one year;</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>that the price to hire will be invoiced at a frequency agreed between you and us; and</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>to allow us to inspect, maintain and periodically test the goods.</Text></Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>8.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                The hire period extends from the day of hiring to the day of return including both of these days as a whole day. No adjustment will be made for holidays or reasons beyond our control such as strikes, lockouts, government interference, acts of God and transport delays.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>9</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What if the goods are incorrect or you want to return the goods?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>9.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Return of goods due to fault, incorrect or short supply or damage by us is subject to:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>you notifying us within:</Text>
                </View>
              </View>
              <View style={styles.termsWrapper}>
                <View style={styles.subListWrapper}>
                  <View style={styles.listWrapper}>
                    <View style={styles.indexList}><Text style={styles.list}>o</Text></View>
                    <View style={styles.textListWrapper}>
                      <Text style={styles.textList}>the warranty or guarantee period for faulty goods; or</Text>
                    </View>
                  </View>
                  <View style={styles.listWrapper}>
                    <View style={styles.indexList}><Text style={styles.list}>o</Text></View>
                    <View style={styles.textListWrapper}>
                      <Text style={styles.textList}>forty eight (48) hours of delivery/pick up for incorrect or short supply or damage (non-notification is deemed acceptance of the condition);</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>the delivery docket being endorsed accordingly;</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>any goods must not be destroyed and must not be removed from the delivery address until we have inspected the goods or required the goods be returned to us;</Text></Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>

    <Page size="A4" style={styles.page}>
      <View style={styles.terms}>
        <View style={styles.sectionTerms}>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}> </Text>
            </View>
            <View style={styles.textWrapper}>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>you are responsible for the cost of return; and</Text></Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}><Text style={styles.textList}>we may refund, repair or replace the goods or credit your account at our discretion.</Text></Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>9.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Return of goods for reasons other than those in 9.1 and termination of the hire period, is subject to:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>you being responsible for the return of all goods to us;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>you notifying us at least twenty four (24) hours before drop off or pick up is required; and</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}> </Text>
            </View>
            <View style={styles.textWrapper}>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>the goods being in the same condition as when provided to you.  On pick up we will count the equipment and if all goods are there we will issue to you a Hire Agreement Return Docket, due to workload we will check the condition of the goods within forty eight (48) hours of pick up (non-notification is deemed acceptance of the condition).</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>9.3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Any goods that are not returned, lost or cannot be produced seven (7) days after notice will be deemed “unreturned goods”. You will be responsible for the cost of replacing unreturned goods, however where unreturned goods are later returned or recovered and used by us, you will receive a credit equal to the sum deducted from your account.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>10</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                When are we responsible for the goods?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>10.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                We are responsible for the goods where erection:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>is part of our service, until the goods are erected by us (we resume responsibility when the goods are dismantled by us).</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>is not part of our service, until the goods are picked up by you or delivered; or</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>10.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Delivery is complete when we give the goods to you or another person/entity who will give the goods to you or when we leave the goods on your premises.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>10.3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                The time and date stated for delivery, erection or removal is an estimate only and not an essential term of our agreement. We may partially deliver, erect or remove goods listed in one order or invoice without added cost to you. If the agreed delivery or removal cannot occur for reasons beyond our control and the agreed lay down area is unavailable you may be charged additional costs for labour per hour and transport.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>10.4</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Risk for the goods lies with us in accordance with 10.1 or when ownership passes in accordance with 11.1, whichever comes first.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>11</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What ownership and security rights do we have?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>11.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                We retain ownership of and hold a security interest in all goods until you have paid us in full for all goods and services provided to you.  While we retain ownership, you will store goods in such a way that they can be identified as provided by us.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>11.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                You agree that we hold security interest in all of your present and after acquired property and:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>authorise us to register a financing statement and charge on the Personal Property Securities Register, and provide all information and signatures necessary to effect the same;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>will not register a financing charge or statement or charge demand in respect of goods without our prior written consent;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>waive your entitlement under s 148 of the Personal Property Securities Act 1999 (PPSA) to receive a copy of a verification statement where we have registered our interest;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>that both parties contract out of ss 114(1)(a), 133 and 134 of the PPSA; and</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>waive your rights as listed under ss 107(2) and 132 of the PPSA.</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>11.3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                If you default we may exercise a lien against any goods in our possession.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>11.4</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                You agree that your failure to pay for goods and services by the due date gives rise to a legal or equitable estate or interest (“the interest”) in your land on which the goods and services were carried out and affixed and that the interest entitles us to register a caveat against your land. A caveat is a notice registered against the title of your land, which informs anyone who searches that title that we have a right to or interest in that land.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>11.5</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                We own all existing and new intellectual property rights connected to the goods and services and brands associated with the same. You fully indemnify us for any intellectual property infringements we may make when acting in accordance with your instructions. You may use the goods only if paid in full and for the purpose for which they were intended and supplied by us.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>12</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What health and safety requirements apply?
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>12.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                You agree to comply with all relevant health and safety law and have a ticketed certified scaffolder erect the scaffolding (where erection is not part of our service).
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>12.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Scaffolding where a fall of five (5) metres or more may occur or classified as special scaffolding will be maintained under the Aculog Scaffold Register System which requires weekly inspections by us when in use and monthly inspections when unused.  Each inspection incurs an additional cost per man per hour plus  transport expenses.
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.sectionTerms}>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>12.3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                You fully indemnify us in respect of all health and safety and injury or damage claims whatsoever arising in connection with use of the goods and costs and charges arising from the same, during the period for which you are responsible for the goods.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>13</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What if you want to place/vary/cancel an order?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>13.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                All orders placed by you are subject to these terms.  If you wish to cancel or vary an order and we have reasonably relied on your original instructions, you will still be responsible for full payment of the price of the order.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>14</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                When may we cancel this agreement?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>14.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                We have the right by notice to suspend or cancel any part of any agreement for the provision of goods and services to you if you default by:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>failing to pay or indicating you will not pay any sum owing by the due date;</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}> </Text>
            </View>
            <View style={styles.textWrapper}>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>any of your creditors seizing or indicating they will seize any goods provided to you;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>goods in your possession becoming materially damaged while any amount remains unpaid;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>being bankrupted, insolvent, under statutory management or put into liquidation;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>a receiver being appointed over or a landlord possessing any of your assets;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>a court judgment entered against you remaining unsatisfied for seven (7) days;</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>breaching the terms of this agreement; and</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>an adverse material change in your financial position.</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>14.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                You agree that if you default and the default is not remedied within seven (7) days of occurrence, we may enter any premises occupied by you to inspect or retrieve any goods and/or Aculog register cards. We may store and/or sell any goods and credit the net sale proceeds to your account for the invoice value less adjustment for the condition of the goods, depreciation and loss or profit.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>14.3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Cancellation or suspension will not affect our claims for any amount due at the time of cancellation or suspension, damages for any breach of your obligations under this agreement and any other legal rights we may have.  Upon cancellation of this agreement any amount owed by you for work done up to and including the date of cancellation and current orders terminate. You will be responsible for costs consequential to suspension or cancellation.
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>15</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                Does a personal guarantee apply?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>15.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                If you are a director of a company or the trustee of a trust:
              </Text>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>in exchange for us agreeing to supply goods and services and grant credit to the company or the trust, you also sign this agreement in your personal capacity, and jointly and severally personally undertake as principal debtors, to pay everything that the company or trust owes us, and to indemnify us against non-payment and/or default; and</Text>
                </View>
              </View>
              <View style={styles.listWrapper}>
                <View style={styles.indexList}><Text style={styles.list}>-</Text></View>
                <View style={styles.textListWrapper}>
                  <Text style={styles.textList}>any personal liability of you as director or trustee will not exclude the company or trust from the liabilities and obligations contained in this agreement. </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.indexBold}>16</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.titleTerms}>
                What else is agreed?
              </Text>
            </View>
          </View>
          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>16.1</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                You are responsible for providing all engineering calculations and certificates and local body permits at your sole cost.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>16.2</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                We may outsource (contract out) part of the work required to produce the goods and services. You agree to pay for all amounts due in connection with the outsourcing.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>16.3</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                If we fail to enforce any of the terms of this agreement it will not be deemed to be a waiver of any of the rights or obligations we have under this agreement.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>16.4</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                If any of these terms are determined to be invalid, void, illegal or unenforceable, the validity, existence, legality and enforceability of the remaining terms will not be affected.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>16.5</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                This is the entire agreement superseding all prior agreements, representations and warranties.  Any instructions we receive from you will be subject to this agreement.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>16.6</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                If any dispute arises between the parties either party must notify the other within seven (7) days of the date of our invoice, or within forty eight (48) hours of delivery if the dispute relates to delivery. Any good the subject of a claim must not be destroyed or removed from the delivery address until we have inspected the goods or required the goods be returned to us.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>16.7</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Documentation related to this agreement may be served on you by email.
              </Text>
            </View>
          </View>

          <View style={styles.termsWrapper}>
            <View style={styles.indexWrapper}>
              <Text style={styles.index}>16.8</Text>
            </View>
            <View style={styles.textWrapper}>
              <Text style={styles.textTerms}>
                Any  changes we make to these terms will be notified to you or published on our website, all other variations must be made by mutual agreement in writing.
              </Text>
            </View>
          </View>

        </View>
      </View>
    </Page>
  </>
);
