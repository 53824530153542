import moment from "moment";
import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllVisits() {
  const { data, error } = await supabase
    .from("visits")
    .select("*, staff:team_leader_id(id, staff_name), jobs:job_id(id, site, job_num) ")
    .order("date", { ascending: false });

  const sortedData = data.sort(
    (a, b) => moment(b.date, "DD/MM/YYYY").toDate() - moment(a.date, "DD/MM/YYYY").toDate(),
  );

  if (error) {
    throw new Error(error.message);
  }
  return sortedData;
}

export function useVisits() {
  return useQuery("visits", () => fetchAllVisits());
}
