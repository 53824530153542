import { useQuery } from "react-query";
import supabase from "../supabase";

export async function getCrewPayouts() {
  const { data, error } = await supabase
    .from("crew_payouts")
    .select("*, crew:crew_id(*), staff_member:staff_id(*), visit:visit_id(*), task:task_id(*)")
    .order("id", { ascending: false });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useCrewPayouts() {
  return useQuery("crew_payouts", () => getCrewPayouts());
}
