import React, { useState, useEffect } from "react";

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { Container } from "../../utils";

import { PageHeading, Badge, Spinner } from "../../common";
import { JobsApi } from "../../api";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";

export const MapsMain = () => {
    const [points, setPoints] = useState(null);
    const jobsQuery = JobsApi.useJobs();

    useEffect(() => {
        if (!points && jobsQuery.data) {
            getCoordinates(jobsQuery.data, setPoints);
        }
    }, [jobsQuery]);

    if (jobsQuery.isLoading || !points) {
        return <div className="w-full h-48 flex justify-center items-center">
            <Spinner />
        </div>
    }

    const vehicleIconMap = new Icon({
        iconUrl: '/marker.svg',
        iconSize: [40, 40]
    });

    return (
        <>
            <PageHeading title="Jobs" />
            <Container>
                <MapContainer
                    style={{ height: "80vh", width: "100%" }}
                    center={[-36.84853, 174.76349]}
                    zoom="10" >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {points.map(row => (
                        <Marker key={row.id} icon={vehicleIconMap} position={[row.latitude, row.longitude]}>
                            <Popup key={row.id}>
                                •Job Number: {row.job?.job_num}
                                <br />
                                •Client: {row.job?.clients?.client_name}
                                <br />
                                •Client Type: {row.job?.clientType}
                                <br />
                                •Site: {row.job?.site}
                                <br />
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </Container>
        </>
    );
};

async function getCoordinates(jobs, setPoints) {
    const points = [];
    for (const job of jobs) {
        if (job.longitude !== null && job.latitude !== null) {
            points.push({
                latitude: Number(job.latitude) || 0, longitude: Number(job.longitude) || 0, job
            });
        }
        else {
            const params = {
                q: job.site,
                format: "json",
                addressdetails: 1,
                polygon_geojson: 0,
            };
            const queryString = new URLSearchParams(params).toString();
            const requestOptions = {
                method: "GET",
                redirect: "follow",
                crossOriginIsolated: true
            };
            // eslint-disable-next-line no-await-in-loop
            const response = await fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
                .catch(error => { console.error(error) })
            // eslint-disable-next-line no-await-in-loop
            const json = await response.json();

            if (json.length > 0) {
                points.push({
                    latitude: Number(json[0].lat) || 0, longitude: Number(json[0].lon) || 0, job
                });
            }
        }
    }
    setPoints(points);
}