import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchStaffByName(name) {
  const { data, error } = await supabase
    .from("staff")
    .select("id").eq('staff_name', name);

  if (error) {
    throw new Error(error.message);
  }
  return data[0];
}

export function useStaffByName(name) {
  return useQuery("staffnames", () => fetchStaffByName(name));
}
