/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik, useFormik } from "formik";
import { SideModal, Input, TextArea, Dropdown } from "../../common";
import { TimesheetsApi, VisitsApi, JobsApi } from "../../api";

export function EditTimesheetDetails({ open, setOpen, data, setEditFormDetail }) {
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [dataLoaded, setDataLoaded] = useState(false);

  const jobQuery = JobsApi.useJobsActive();
  const visitsQuery = VisitsApi.useVisits();
  const updateTimesheetMutation = TimesheetsApi.useUpdateTimesheet();

  useEffect(() => {
    if (data?.job) {
      setSearchValue(data.job);
    }
  }, [data, options]);

  useEffect(() => {
    if (options.length > 0) {
      const newFilteredOptions = options.filter((option) => {
        if (option.label) {
          return option.label.toLowerCase().includes(searchValue.toLowerCase());
        }
        return false;
      });
      setFilteredOptions(newFilteredOptions);
    }
  }, [searchValue, options]);

  useEffect(() => {
    if (jobQuery.data && !dataLoaded) {
      const jobs = jobQuery.data.map((e) => e.code);
      const uniqueJobs = jobs.filter((item, index) => jobs.indexOf(item) === index);
      const options = uniqueJobs.map((e) => ({ value: e, label: e }));
      setOptions(options);
      setDataLoaded(true);
    }
  }, [jobQuery.data, dataLoaded]);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      job: data.job || "",
      description: data?.description || "",
      total_hours: data?.hours || 0,
    },
    onSubmit: (values) => {
      const checkChangeVisit = visitsQuery.data.find(
        (e) => e.id === data.visitLinkId && e.jobs.oldjobquote === values.job,
      );
      const firstVisitByJob = visitsQuery.data.find((e) => e.jobs.oldjobquote === values.job);
      const visitID = checkChangeVisit !== undefined ? data.visitLinkId : firstVisitByJob?.id;
      let payload = {};

      if (data.id === 1)
        payload = {
          visit_1: visitID,
          labourDescription_1: values.description,
          labourHours_1: Number(values.total_hours),
          job1: values.job,
        };
      if (data.id === 2)
        payload = {
          visit_2: visitID,
          labourDescription_2: values.description,
          labourHours_2: Number(values.total_hours),
          job2: values.job,
        };
      if (data.id === 3)
        payload = {
          visit_3: visitID,
          labourDescription_3: values.description,
          labourHours_3: Number(values.total_hours),
          job3: values.job,
        };
      if (data.id === 4)
        payload = {
          visit_4: visitID,
          labourDescription_4: values.description,
          labourHours_4: Number(values.total_hours),
          job4: values.job,
        };
      if (data.id === 5)
        payload = {
          visit_5: visitID,
          labourDescription_5: values.description,
          labourHours_5: Number(values.total_hours),
          job5: values.job,
        };
      if (data.id === 6)
        payload = {
          visit_6: visitID,
          labourDescription_6: values.description,
          labourHours_6: Number(values.total_hours),
          job6: values.job,
        };

      try {
        updateTimesheetMutation.mutateAsync({
          timesheet: payload,
          timesheetId: Number(data.timesheetId),
        });
        resetForm();
        setOpen(false);
        setEditFormDetail(null);
      } catch (err) {
        console.log("ERROR CREATING JOB", err);
      }
    },
  });

  if ((jobQuery.isLoading && visitsQuery.isLoading) || !data || (data.job && options.length === 0))
    return null;

  return (
    <div>
      <SideModal
        heading="Update Timesheet"
        open={open}
        setOpen={setOpen}
        handleSubmit={handleSubmit}
        isLoading={isSubmitting}
        formType="edit"
      >
        <div className="flex items-center">
          <Dropdown
            label="Greentree job number"
            id="job"
            options={filteredOptions}
            error={errors.job}
            value={values.job}
            onChange={setFieldValue}
            setSearchValueprop={setSearchValue}
            onBlur={setFieldTouched}
          />
        </div>
        <div className="flex items-center">
          <Input
            title="Site"
            id="site"
            type="text"
            handleChange={() => null}
            handleBlur={handleBlur}
            value={jobQuery?.data?.find((e) => e?.code === values.job)?.site || ""}
          />
          <Input
            title="Client"
            id="client"
            type="text"
            handleChange={() => null}
            handleBlur={handleBlur}
            value={jobQuery?.data?.find((e) => e.code === values.job)?.clients?.client_name || ""}
          />
        </div>
        <div className="">
          <TextArea
            title="Description"
            id="description"
            type="text"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.description}
          />
          <Input
            title="Total Hours"
            id="total_hours"
            type="number"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.total_hours}
          />
        </div>
      </SideModal>
    </div>
  );
}
