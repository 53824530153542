import { useQuery } from "react-query";
import supabase from "../supabase";

export async function getCrewsByID(id) {
  const { data, error } = await supabase
    .from("crews")
    .select("*, staff:crewLead(id,staff_name)")
    .match({ id });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useCrewsByID(id) {
  return useQuery({
    queryKey: ["crewses", id],
    queryFn: () => getCrewsByID(id),
  });
}