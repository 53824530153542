import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { PencilSquareIcon, FolderOpenIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ConfirmationDialog } from "../../common/Confirmation/Confirmation";

import { Container } from "../../utils";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { PageHeading, Badge, Button as CustomButton } from "../../common";
import { StaffApi, CrewsApi } from "../../api";
import { CrewForm } from "../../components/crews";
import { useUpdateStaff } from "../../api/Staff";

export { CrewDetails } from "./Details";
export { EditCrew } from "./Edit";

export const CrewsMain = () => {
  const staffQuery = StaffApi.useStaff();
  const crewsQuery = CrewsApi.useCrews();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const updateStaffMutation = useUpdateStaff();

  const [filters, setFilters] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue] = useState("");
  const updateCrewMutation = CrewsApi.useUpdateCrew();
  const jobStatusDropdowns = ["Active", "Inactive"];

  const dt = React.useRef(null);
  const crewConditionsDropdowns = [
    {
      label: "Equals",
      value: FilterMatchMode.EQUALS,
    },
    {
      label: "Not equals",
      value: FilterMatchMode.NOT_EQUALS,
    },
  ];
  useEffect(() => {
    initFilters();
  }, []);
  const formatDate = (value) =>
    value.toLocaleDateString("en-NZ", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  const clearFilter = () => {
    initFilters();
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      status: { value: "Active", matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const statusFilterTemplate = (options) => (
    <>
      <Dropdown
        value={filters?.status?.matchMode}
        options={crewConditionsDropdowns}
        onChange={(e) =>
          setFilters({ ...filters, status: { ...filters?.status, matchMode: e.value } })
        }
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
      <br />
      <Dropdown
        value={options.value}
        options={jobStatusDropdowns}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={(option) => option}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        defaultValue="Active"
      />
    </>
  );
  const renderHeader = () => (
    <div className="-mb-12 -mt-8">
      <div className="flex items-center">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear Filters"
          className="p-button-outlined"
          onClick={clearFilter}
        />
        <span className="p-input-icon-left ml-2">
          {/* <i className="pi pi-search" /> */}
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange}
            placeholder="Search Crew"
          />
        </span>
      </div>
      {/*
      <div className="mt-4">
        <Button
          type="button"
          icon="pi pi-file"
          label="Export"
          onClick={() => exportCSV(false)}
          className="p-mr-2 p-button-outlined"
          data-pr-tooltip="CSV"
        />
      </div>
      */}
    </div>
  );

  const headerTemplate = (data) => (
    <td className="" colSpan="6">
      <span className="text-gray-900 font-bold">
        {data.job_id + 1000} - {data.jobs.site}
      </span>
    </td>
  );

  console.log(crewsQuery.data);

  return (
    <div>
      <PageHeading title="Crews" createBtn="Create Crew" isEditable={false} setOpen={setOpen} />
      <Container>
        <div className="mx-auto mt-8">
          <DataTable
            ref={dt}
            value={crewsQuery?.data?.filter(
              (record) => record.status === "Active" || record.status === "Inactive",
            )}
            loading={staffQuery.isLoading && crewsQuery.isLoading}
            header={renderHeader()}
            paginator
            paginatorPosition="top|bottom|both"
            showGridlines
            rows={100}
            rowsPerPageOptions={[25, 50, 100]}
            dataKey="id"
            filters={filters}
            filterDisplay="menu"
            // stripedRows
            responsiveLayout="scroll"
            globalFilterFields={["staff_name", "type", "mobile", "position", "pin", "status"]}
            emptyMessage="No Crews found."
            scrollHeight="600px"
          >
            {/* <Column field="job_id" header="Job" /> */}
            <Column
              header="Crew Name (Details)"
              field="crewName"
              // filterField="time_on"
              style={{ textAlign: "center" }}
              body={(row) => (
                <Link
                  key={`details${row.id}`}
                  to={`crew/${row.id}/details`}
                  className="flex items-center"
                >
                  <FolderOpenIcon className="h-4 w-4 text-gray-500 mr-2" />
                  <span className="hover:text-gray-800">{row.crewName}</span>
                </Link>
              )}
            />
            <Column
              header="Team Lead"
              bodyClassName="p-text-center"
              body={(row) => (
                <div>
                  <span className="hover:text-gray-800">{row.staff?.staff_name}</span>
                </div>
              )}
            />
            <Column
              header="Staff Members"
              bodyClassName="p-text-center"
              body={(row) => (
                <div>
                  <span className="hover:text-gray-800">
                    {staffQuery?.data
                      ?.filter((e) => e.crew === row.id)
                      ?.map((e) => e.staff_name)
                      ?.toString()}
                  </span>
                </div>
              )}
            />
            <Column
              field="status"
              header="Status"
              bodyClassName="p-text-center"
              style={{ width: "10rem", textAlign: "center" }}
              body={(row) => <Badge type={row.status} text={row.status} />}
              filter
              filterElement={statusFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
              showFilterOperator={false}
              showAddButton={false}
              filterMatchMode={filters?.status?.matchMode}
              showFilterMatchModes={false}
            />
            <Column
              header="Edit"
              bodyClassName="p-text-center"
              style={{ width: "3rem" }}
              body={(row) => (
                <Link
                  to={{
                    pathname: `crew/${row.id}/editCrew`,
                    state: { background: location, name: "editCrew" },
                  }}
                >
                  <PencilSquareIcon className="text-gray-600 h-4 w-4" />
                </Link>
              )}
            />
            <Column
              header="Delete"
              body={(row) => (
                <ConfirmationDialog
                  isDone={updateCrewMutation.isSuccess}
                  icon="XMarkIcon"
                  title="Delete Crew"
                  body="Approve Delete Crew."
                  triggerButton={
                    <button type="button">
                      <XMarkIcon className="h-4 w-4 text-gray-600" />
                    </button>
                  }
                  confirmButton={
                    <CustomButton
                      isLoading={updateCrewMutation?.isLoading}
                      variant="primary"
                      onClick={async (e) => {
                        e.preventDefault();
                        const DataStaff = staffQuery?.data?.filter((e) => e.crew === row.id);
                        try {
                          await updateStaffMutation.mutateAsync({
                            staff: { crew: null },
                            staffId: Number(DataStaff[0].id),
                          });
                          await updateCrewMutation.mutateAsync({
                            crewPayload: { status: "Deleted", crewLead: null },
                            id: row.id,
                          });
                        } catch (err) {
                          console.log("ERROR CREW", err);
                        }
                      }}
                    >
                      Delete Crew
                    </CustomButton>
                  }
                />
              )}
              style={{ width: "4rem" }}
              bodyStyle={{ textAlign: "center" }}
            />
          </DataTable>
        </div>
      </Container>
      {open && <CrewForm heading="Create Crew" open={open} setOpen={setOpen} />}
    </div>
  );
};
