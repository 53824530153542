import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import clsx from 'clsx';
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { Button, Table, Spinner, Badge } from "../../common";

import { JobsApi, QuotesApi, CrewsApi, StaffApi, VisitsApi, CrewPayoutsApi } from "../../api";
import { numberFormat } from "../../utils";

export const CrewPayouts = ({ staffId, jobId, visitId }) => {

  const queryClient = useQueryClient();
  const crewsQuery = CrewsApi.useCrews();
  const staffQuery = StaffApi.useStaff();
  const crewPayoutQuery = CrewPayoutsApi.useCrewPayouts();
  const visitsQuery = JobsApi.useVisits();
  
  if (crewsQuery.isLoading || staffQuery.isLoading || crewPayoutQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  console.log(crewPayoutQuery.data);
  let otherRows = crewPayoutQuery.data.map((crewpayout) => ({
    ...crewpayout,
    created_at: moment(Date(crewpayout.created_at)).format('DD/MM/YYYY')
  }));
  console.log(otherRows);
  console.log(staffId);
  if(staffId)
  {
    otherRows = otherRows.filter((row) => Number(row.staff_id) === Number(staffId))
  }
  if(visitId)
  {
    otherRows = otherRows.filter((row) => Number(row.visit_id) === Number(visitId))
  }
  if(jobId) // TODO: Add jobId code to pull list of visits and show only crewpayouts on those visits.
  {
    otherRows = otherRows.filter((row) => Number(row.job_id) === Number(jobId))
  }
  console.log(otherRows);


  return (
    <div className="w-full mx-auto mt-8">
      <div>
        <h2 className="text-lg px-8 mb-2 leading-6 font-large text-gray-900 mt-6">Crew Payout By Task</h2>
        <Table
          cols={[
            {
              Header: "Payout Locked-in Date",
              accessor: "created_at",
            },
            {
              Header: "Visit Date",
              accessor: "visit.date"
            },
            {
              Header: "Task",
              accessor: "task.description"
            },
            {
              Header: "Crew Name",
              accessor: "crew.crewName",
            },
            {
              Header: "Staff Name",
              accessor: "staff_name",
            },
            {
              Header: "Payout Amount",
              accessor: "visit_pay",
              Cell: ({ row }) => {
                const value = row?.original?.visit_pay
                  ? numberFormat.format(row?.original?.visit_pay)
                  : ' - ';
                  return value;
              }
            },
          ]}
          tableData={otherRows}
          // searchPlaceholder="Search Scaffold Tags"
          displaySearch={false}
          isBasic
          displayPagination={false}
          pageSize={1000}
        />
      </div>
    </div>
  );
};
