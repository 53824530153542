import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchStaffWithNoCrew() {
  const { data, error } = await supabase
    .from("staff")
    .select("*")
    .match({ status: "Active", crew: null })
    .order("staff_name", { ascending: true });;

  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useStaffWithOutCrew() {
  return useQuery("staffWithOutCrewes", () => fetchStaffWithNoCrew());
}
