import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchVisit(id) {
  const { data: visitData, error: visitError } = await supabase
    .from("visits")
    .select("*, staff:team_leader_id(*), jobs:job_id(id, site), crew_data:crews(*), task_ids")
    .eq("id", Number(id))
    .order("id", { ascending: true });

  if (visitError) {
    throw new Error(visitError.message);
  }

  const visitTaskIds = visitData.map(visit => visit.task_ids).flat(); // Extract all task IDs from the visits
  
  const { data: tasksData, error: tasksError } = await supabase
    .from("job_tasks")
    .select("*")
    .in("id", visitTaskIds)
    .order("id", { ascending: true });

  if (tasksError) {
    throw new Error(tasksError.message);
  }

  // Merge the tasks data with the visit data based on task IDs
  const visitsWithTasks = visitData.map(visit => ({
    ...visit,
    tasks: tasksData.filter(task => visit.task_ids.includes(task.id))
  }));

  return visitsWithTasks[0];
}

export function useFetchVisit(visitId) {
  return useQuery({
    queryKey: ["visit", visitId],
    queryFn: () => fetchVisit(visitId),
  });
}
