import React, { useEffect, useState } from "react";
import moment from "moment";

import { Link, useLocation } from "react-router-dom";
import { PencilSquareIcon, FolderOpenIcon } from "@heroicons/react/24/solid";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { PageHeading, Button, Table, Spinner, Badge } from "../../../common";
import { Container } from "../../../utils";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";



import { JobsApi, StaffApi } from "../../../api";

// eslint-disable-next-line react/function-component-definition
export const VisitTimesheetTable = ({ jobId }) => {
  const timesheetsQuery = JobsApi.useFetchVisitTimesheets(jobId);
  const staffQuery = StaffApi.useStaff();
  const dt = React.useRef(null);
  if (timesheetsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!timesheetsQuery.data) return null;

  const data = [];
// TODO: Add teamleader to front of visit text also add crew payout in the top
  for (const timesheet of timesheetsQuery.data) {
    console.log(timesheet);
    const start = moment(timesheet.time_in, "HH:mm");
    const finish = moment(timesheet.time_off, "HH:mm");
  // TODO: Recalculate hours for each person
    // console.log(`staffData: ${JSON.stringify(staffQuery.data)}`);
    const supervisorStaff = [{
      visit: (`${timesheet.visits?.type} on ${timesheet.visits?.date} from ${timesheet.time_in} to ${timesheet.time_off ? timesheet.time_off : 'N/A'} with Crew: ${timesheet.visits?.crews?.crewName}`),
      timesheet_id: timesheet.id,
      timesheet_date: timesheet.visits?.date,
      timesheet_startdate: `${timesheet.visits?.date}-${timesheet.time_in}`,
      staff: timesheet.staff?.staff_name,
      staff_crew_type: "Team Lead",
      time_in: timesheet.time_in,
      time_off: timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === timesheet.staff?.staff_id)[0]?.timeLeft || timesheet.time_off,
      visit_id: timesheet.visit_id,
      visit_type: timesheet.visits?.type,
      total_hours: (moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === timesheet.staff?.staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(timesheet.time_in, "HH:mm"))).asHours().toFixed(2) !== "NaN") ? moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === timesheet.staff?.staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(timesheet.time_in, "HH:mm"))).asHours().toFixed(2) : "",
    }];
    data.push(...supervisorStaff);

    const crewStaff = timesheet.staff_ids.map((staff_id) => ({
      visit: (`${timesheet.visits?.type} on ${timesheet.visits?.date} from ${timesheet.time_in} to ${timesheet.time_off ? timesheet.time_off : 'N/A'} with Crew: ${timesheet.visits?.crews?.crewName}`),
      timesheet_id: timesheet.id,
      timesheet_date: timesheet.visits?.date,
      timesheet_startdate: `${timesheet.visits?.date}-${timesheet.time_in}`,
      staff: staffQuery.data.filter((staff_record) => staff_record.id === Number(staff_id))[0]?.staff_name,// staff_id,
      staff_crew_type: "Staff Member",
      time_in: timesheet.time_in,
      time_off: timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff_id)[0]?.timeLeft || timesheet.time_off,
      visit_type: timesheet.visits?.type,
      total_hours: (moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(timesheet.time_in, "HH:mm"))).asHours().toFixed(2) !== "NaN")? moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(timesheet.time_in, "HH:mm"))).asHours().toFixed(2) : "",
    }));
    data.push(...crewStaff);

    const additionalStaff = timesheet.additionalStaff.map((staff) => ({
      visit: (`${timesheet.visits?.type} on ${timesheet.visits?.date} from ${timesheet.time_in} to ${timesheet.time_off ? timesheet.time_off : 'N/A'} with Crew: ${timesheet.visits?.crews?.crewName}`),
      timesheet_id: timesheet.id,
      timesheet_date: timesheet.visits?.date,
      timesheet_startdate: `${timesheet.visits?.date}-${timesheet.time_in}`,
      staff: staff.staff_name,
      staff_crew_type: "Additional Staff",
      time_in: staff.timeArrived,
      time_off: timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff.staff_id)[0]?.timeLeft || timesheet.time_off,
      visit_id: timesheet.visit_id,
      visit_type: timesheet.visits?.type,
      total_hours: (moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff.staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(staff.timeArrived, "HH:mm"))).asHours().toFixed(2) !== "NaN") ? moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff.staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(staff.timeArrived, "HH:mm"))).asHours().toFixed(2) : "",
    }));
    data.push(...additionalStaff);

  }
  // console.log(`data: ${JSON.stringify(data)}`);
  // const [filters, setFilters] = useState(null);
  // const [globalFilterValue1, setGlobalFilterValue] = useState("");


  // const clearFilter = () => {
  //   initFilters();
  // };

  // const initFilters = () => {
  //   setFilters({
  //     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  //     last_inspection: {
  //       operator: FilterOperator.AND,
  //       constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  //     },
  //     inspection_due: {
  //       operator: FilterOperator.AND,
  //       constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  //     },
  //     status: { value: "Active", matchMode: FilterMatchMode.EQUALS },
  //   });
  //   setGlobalFilterValue("");
  // };

  // const onGlobalFilterChange = (e) => {
  //   const { value } = e.target;
  //   const _filters = { ...filters };
  //   _filters.global.value = value;

  //   setFilters(_filters);
  //   setGlobalFilterValue(value);
  // };

  const renderHeader = () => (
    <div className="-mb-12 -mt-8">
      <div className="flex items-center"/>
    </div>
  );

  const headerTemplate = (data) => (
    <td className="" colSpan="6">
      <span className="text-gray-900 font-bold">
      {data.visit}
      </span>
    </td>
  );

  return (
    <div>
      
        <PageHeading title="Visit Timesheets" isEditable={false} />
      
    
      <Container>
        <div className="mx-auto mt-8">
          <DataTable
            ref={dt}
            value={data}
            // loading={visitsQuery.isLoading}
            paginator
            paginatorPosition="top|bottom|both"
            showGridlines
            rows={25}
            rowsPerPageOptions={[25, 50, 100]}
            dataKey="id"
            // filters={filters}
            filterDisplay="menu"
            stripedRows
            responsiveLayout="scroll"
            globalFilterFields={[
              "id",
            ]}
            header={renderHeader()}
            emptyMessage="No visit timesheets found."
            rowGroupMode="subheader"
            groupRowsBy="timesheet_id"
            sortable
            sortField="timesheet_startdate"
            sortOrder={-1}
            // sortMode="multiple"
            rowGroupHeaderTemplate={headerTemplate}
            // selection={selectedTimesheets}
            // onSelectionChange={(e) => setSelectedTimesheets(e.value)}
            scrollHeight="600px"
          >
            {/* <Column field="job_id" header="Job" /> */}
            <Column header="Date" field="timesheet_date" />
            {/* <Column header="Timesheet ID" field="timesheet_id" /> */}
            {/* <Column header="Visit ID" field="visit_id" /> */}
            <Column header="Staff Name" field="staff" />
            <Column header="Staff Type" field="staff_crew_type" />
            <Column header="Time In" field="time_in" />
            <Column header="Time Off" field="time_off" />
            <Column header="Total Hours" field="total_hours" />
            <Column header="Visit Type" field="visit_type" />
            {/* <Column header="Task Percentage" field="task_percent" />
            <Column header="Individual Payout" field="individual_payout" /> */}
        
          </DataTable>
        </div>
      </Container>
    </div>
  );
  // return (
  //   <div className="w-full mx-auto mt-8">
  //     <div>
  //       <h2 className="text-lg px-8 mb-2 leading-6 font-large text-gray-900 mt-6">
  //         Visit Timesheets
  //       </h2>

  //       <Table
  //         cols={[
  //           {
  //             Header: "Date",
  //             accessor: "date",
  //           },
  //           {
  //             Header: "Timesheet ID",
  //             accessor: "timesheet_id",
  //           },
  //           {
  //             Header: "Visit ID",
  //             accessor: "visit_id",
  //           },
  //           {
  //             Header: "Staff Name",
  //             accessor: "staff",
  //           },
  //           {
  //             Header: "Time In",
  //             accessor: "time_in",
  //           },
  //           {
  //             Header: "Time Off",
  //             accessor: "time_off",
  //           },
  //           /* {
  //             Header: "#STAFF",
  //             accessor: "staff_ids",
  //             Cell: ({ row }) => {
  //               const totalStaff = row?.original?.staff_ids?.length + 1

  //               return `${totalStaff}`;
  //             },
  //           }, */
  //           {
  //             Header: "Total Hours",
  //             accessor: "hours",
  //           },
  //           // {
  //           //   Header: "Status",
  //           //   Cell: ({ row }) => {
  //           //     const type = row?.original?.status;
  //           //     return <Badge type={type} text={type} />;
  //           //   },
  //           //   width: 60,
  //           //   accessor: "status",
  //           // },
  //         ]}
  //         tableData={data}
  //         searchPlaceholder="Search Timesheets"
  //         displayPagination={timesheetsQuery?.data?.length}
  //       />
  //     </div>
  //   </div>
  // );
};
