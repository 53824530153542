import React, { useEffect, useState } from "react";
import moment from 'moment'
import { Button, Table, Spinner, Badge } from "../../common";

import { JobsApi } from "../../api";

export const JobvsHours = ({ jobId }) => {
  const timesheetsQuery = JobsApi.useFetchVisitTimesheets(jobId)
  const taskQuery = JobsApi.useTasks(jobId)

  if (timesheetsQuery.isLoading || taskQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!timesheetsQuery.data) return null;

  const dataForTable = [{ estimatedHours: 0, actualHours: 0 }]
  const timeData = [];
  for (const timesheet of timesheetsQuery.data) {
    console.log(timesheet);
    const start = moment(timesheet.time_in, "HH:mm");
    const finish = moment(timesheet.time_off, "HH:mm");
  // TODO: Recalculate hours for each person
    // console.log(`staffData: ${JSON.stringify(staffQuery.data)}`);
    const supervisorStaff = [{
      hours_used: (moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === timesheet.staff?.staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(timesheet.time_in, "HH:mm"))).asHours().toFixed(2) !== "NaN") ? moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === timesheet.staff?.staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(timesheet.time_in, "HH:mm"))).asHours().toFixed(2) : "",
    }];
    timeData.push(...supervisorStaff);

    const crewStaff = timesheet.staff_ids.map((staff_id) => ({
      hours_used: (moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(timesheet.time_in, "HH:mm"))).asHours().toFixed(2) !== "NaN")? moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(timesheet.time_in, "HH:mm"))).asHours().toFixed(2) : "",
    }));
    timeData.push(...crewStaff);

    const additionalStaff = timesheet.additionalStaff.map((staff) => ({
      hours_used: (moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff.staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(staff.timeArrived, "HH:mm"))).asHours().toFixed(2) !== "NaN") ? moment.duration(moment((timesheet.earlyDepartures.filter((earlyStaff) => earlyStaff.staff_id === staff.staff_id)[0]?.timeLeft || timesheet.time_off), "HH:mm").diff(moment(staff.timeArrived, "HH:mm"))).asHours().toFixed(2) : "",
    }));
    timeData.push(...additionalStaff);
  }

  for (const row of timeData) {
    dataForTable[0].actualHours += Number(row.hours_used);
  }

  for (const row of taskQuery.data) {
    dataForTable[0].estimatedHours += Number(row.total_hours);
  }

  return (
    <div className="w-full mx-auto mt-8">
      <div>
        <h2 className="text-lg px-8 mb-2 leading-6 font-large text-gray-900 mt-6">
          Quoted vs Actual Hours
        </h2>

        <Table
          cols={[
            {
              Header: "Estimated Hours",
              accessor: "estimatedHours",
            },
            {
              Header: "Actual Hours",
              accessor: "actualHours",
              Cell: (cell) => (
                Number(cell.row.values.actualHours) < Number(cell.row.values.estimatedHours) ?
                <Badge type="Green" text={cell.value} /> : <Badge type="Yellow" text={cell.value} />
              ),
            },
          ]}
          isBasic
          displayPagination={false}
          displaySearch={false}
          tableData={dataForTable}
        />
      </div>
    </div>
  );
};